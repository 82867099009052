export const ADMIN_ROUTE = '/admin';
export const LOGIN_ROUTE = `/signin`;
export const REGISTRATION_ROUTE = `/signup`;
export const REFRESH_TOKEN = `/refresh`;
export const SHOP_ROUTE = '/';
export const ITEM_ROUTE = 'item';
export const CART_ROUTE = '/cart';
export const ORDER_ROUTE = '/orders';
export const CHECKOUT_ROUTE = '/checkout';
export const FORGOT_PASSWORD_ROUTE = '/forgot-password';
export const RESET_PASSWORD_ROUTE = '/reset-password';
export const NOT_FOUND_ROUTE = '*';
