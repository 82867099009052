import IIcon from '../types';
import styles from '../Icons.module.css';

export default function MenuIcon({ onClick }: IIcon) {
	return (
		<div className={styles.icon} onClick={onClick}>
			<svg
				width="38px"
				height="38px"
				viewBox="0 0 20 20"
				xmlns="http://www.w3.org/2000/svg"
				fill="none"
			>
				<g id="SVGRepo_bgCarrier" strokeWidth="0" />

				<g
					id="SVGRepo_tracerCarrier"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>

				<g id="SVGRepo_iconCarrier">
					{' '}
					<path
						fill="#7e1b1b"
						fillRule="evenodd"
						d="M19 4a1 1 0 01-1 1H2a1 1 0 010-2h16a1 1 0 011 1zm0 6a1 1 0 01-1 1H2a1 1 0 110-2h16a1 1 0 011 1zm-1 7a1 1 0 100-2H2a1 1 0 100 2h16z"
					/>{' '}
				</g>
			</svg>
		</div>
	);
}
