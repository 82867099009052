/* eslint-disable no-restricted-syntax */
/* eslint-disable guard-for-in */
/* eslint-disable no-multi-assign */
/* eslint-disable no-param-reassign */
/* eslint-disable no-useless-escape */
export function getCookie(name: string) {
	const matches = document.cookie.match(
		new RegExp(
			`(?:^|; )${name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1')}=([^;]*)`
		)
	);
	return matches ? decodeURIComponent(matches[1]) : undefined;
}

export function setCookie(
	name: string,
	value: string | number | boolean,
	props: any
) {
	props = props || {};
	let exp = props.expires;
	if (typeof exp === 'number' && exp) {
		const d = new Date();
		d.setTime(d.getTime() + exp * 1000);
		exp = props.expires = d;
	}
	if (exp && exp.toUTCString) {
		props.expires = exp.toUTCString();
	}
	value = encodeURIComponent(value);
	let updatedCookie = `${name}=${value}`;
	for (const propName in props) {
		updatedCookie += `; ${propName}`;
		const propValue = props[propName];
		if (propValue !== true) {
			updatedCookie += `=${propValue}`;
		}
	}
	document.cookie = `${updatedCookie};path=/`;
}

export function deleteCookie(name: string) {
	setCookie(name, '', { expires: -1 });
}
